import Link from 'next/link'
import React from 'react'
import { HiXCircle } from 'react-icons/hi'

export const ErrorToastMessage: React.FC<{ setIsShown: any; message: string }> = ({ setIsShown, message }) => {
    return (
        <div className="fixed bottom-0 right-0 z-50 flex items-end justify-center w-full p-6 px-4 py-6 mb-12 pointer-events-none md:mb-0 md:w-1/2 sm:items-start sm:justify-end">
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-gray ring-opacity-5">
                <div className="p-4">
                    <div className="flex items-start">
                        <div className="flex-shrink-0">
                            <HiXCircle className="w-8 h-full hover:bg-gray-300 p-1 text-red-500" />
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                            <p className="text-sm font-medium text-gray-900">{message}</p>
                        </div>
                        <div className="flex flex-shrink-0 ml-4">
                            <button
                                className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => setIsShown(false)}
                            >
                                <span className="sr-only">Close</span>
                                <svg
                                    className="w-5 h-5"
                                    data-todo-x-description="Heroicon name: solid/x"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
